"use client";
import { Button, Card, Skeleton, Spinner } from "@heroui/react";
import { BusinessCard } from "./BusinessCard";
import { DictsTypes } from "@/app/[lang]/dictionaries/dictionaries";
import { useAppDispatch, useAppSelector } from "@/redux/app/hooks";
import { useEffect, useState } from "react";
import { setExchangeData } from "@/redux/features/data/data-slice";
import { getDirectoryData } from "@/utils/api";
import Link from "next/link";
import { Business } from "@/types/dataTypes";
import {
  // FilterState,
  // initializeFilters,
  setPageIndex,
} from "@/redux/features/filters/filter-slice";
import dynamic from "next/dynamic";
import ResultTitleExchange from "@/components/Directory/exchange/ResultBar/ResultTitle";
// import { useRouter } from "next/navigation";
// import { parseFiltersFromPath } from "@/utils/functions/route-filter";

const ResultHeader = dynamic(
  () =>
    import(
      `@/components/Directory/${process?.env?.NEXT_PUBLIC_DIRECTORY}/ResultBar/ResultTitle`
    ),
  {
    loading: () => (
      <div className="flex flex-col gap-4 pt-6 px-2">
        <Skeleton className="h-5 w-1/12 rounded-lg" />
        <Skeleton className="h-3 w-3/5 rounded-lg" />
      </div>
    ),
  }
) as typeof ResultTitleExchange;
const CardResult = ({
  dicts
}: {
  dicts: DictsTypes
}) => {
  const exchangeData = useAppSelector((state) => state.data.data);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const filters = useAppSelector((state) => state.filters.dynamicFilters);
  const { pageIndex, pageSize } = useAppSelector((state) => state.filters);

  // useEffect(() => {
  //   if (initialFilters) {
  //     dispatch(initializeFilters(initialFilters));
  //   }
  // }, [initialFilters, dispatch]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await getDirectoryData(filters, pageSize, pageIndex);
        if (Array.isArray(res.data)) {
          const newData = res.data.filter(
            (item: Business) =>
              !exchangeData.some((existingItem) => existingItem.id === item.id)
          );

          if (newData.length > 0) {
            dispatch(setExchangeData([...(exchangeData || []), ...newData]));
          }
        } else {
          console.error("Unexpected data format:", res.data);
        }
      } catch (error) {
        console.error("Error while fetching exchange data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!loading && pageIndex > 0) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, filters, dispatch]);
  return (
    <Card
      className="flex flex-col px-4 w-full min-w-[300px] lg:!me-4"
      shadow="none"
    >
      <div className="flex flex-col">
        <ResultHeader dicts={dicts} />
        <div>
          <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 py-3 md:gap-3 gap-y-4 lg:gap-3 sm:gap-x-3 px-0">
            {exchangeData?.length > 0 ? (
              exchangeData.map((exchange: Business, index) => (
                <Link
                  href={`${dicts.lang}/b/${exchange?.slug}/${exchange?.publicId}`}
                  key={exchange?.id || index}
                  className="border rounded-lg shadow"
                >
                  <BusinessCard
                    key={exchange?.id || index}
                    dicts={dicts}
                    image={exchange?.image ?? ""}
                    title={exchange?.name}
                  />
                </Link>
              ))
            ) : (
              <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 py-3 md:gap-3 gap-y-4 lg:gap-3 sm:gap-x-3 px-0 col-span-full">
                {Array.from({ length: exchangeData?.length || 20 }).map(
                  (_, index) => (
                    <Card className="h-48" key={`skeleton-${index}`}>
                      <Skeleton className="rounded-lg flex-col items-start p-0 rounded-b-none object-cover w-full">
                        <div className="h-40 rounded-lg bg-default-300" />
                      </Skeleton>
                      <div className="pt-2 pb-5">
                        <Skeleton className="w-3/5 rounded-lg mx-3">
                          <div className="h-5 w-3/5 rounded-lg bg-default-200 " />
                        </Skeleton>
                      </div>
                    </Card>
                  )
                )}
              </div>
            )}
          </div>
        </div>
        {exchangeData?.length >= 25 && (
          <Button
            className="w-max mx-auto my-4 font-normal"
            radius="lg"
            variant="bordered"
            startContent={loading ? <Spinner size="sm" color="default" /> : ""}
            onPress={() => dispatch(setPageIndex(pageIndex + 1))}
            disabled={loading}
            aria-label="load-more"
          >
            {dicts.button.loadmore}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default CardResult;
